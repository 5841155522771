import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types';
import { css, Global } from '@emotion/react';
import { breakpoints } from '~styles/global'
import Section from '~components/Section'
import Image from '~components/Image'
import LevelOneMenuList from '~components/blocks/LevelOneMenuList'
import MenuList from '~components/blocks/MenuList'
import Button from '~components/blocks/Button'
import MenuCard from '~components/blocks/MenuCard'
import ArticleCard from '~components/blocks/ArticleCard'
import { Larr } from '../../Svg';
import useMenuData from "~utils/useMenuData"
import resolveLink from '~utils/resolveLink'
import { DateTime } from 'luxon'
import { useSiteState } from '~context/siteContext'
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import { navigate } from 'gatsby';


const Menu = ({ className, barShowing }) => {

  const [siteState, setSiteState] = useSiteState()

  const {tablet} = breakpoints
  const { links, aboutText, aboutLinkText, aboutImage, showrooms, brands, journalText, journalLinkText, storeCategories, featuredArticles} = useMenuData()

  const [menuButtons, setMenuButtons] = useState([])

  const [levelOne, setLevelOne] = useState(null)
  const [levelTwoHidden, setLevelTwoHidden] = useState(true)

  const [levelTwo, setLevelTwo] = useState(null)
  const [levelThreeHidden, setLevelThreeHidden] = useState(true)

  const [levelThree, setLevelThree] = useState(null)
  const [levelFourHidden, setLevelFourHidden] = useState(true)

  const [updating, setUpdating] = useState(false)
  const [updatingLevelThree, setUpdatingLevelThree] = useState(false)
  const [updatingLevelFour, setUpdatingLevelFour] = useState(false)

  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(()=> {
    if(windowWidth >= 1025 ){
      setMenuButtons(document.querySelectorAll("#menu button, #menu a"))
    } else {
      setMenuButtons(document.querySelectorAll("#menu a"))
    }

  },[levelOne,levelTwo,levelThree, windowWidth])

  useEffect(()=> {
    menuButtons.forEach(button => 
      button.addEventListener("click", closeMenu)
    )
    return ()=> {
      menuButtons.forEach(button => 
        button.removeEventListener("click", closeMenu)
      )
    }
  }, [menuButtons])

  const closeMenu = () => {
    setSiteState({...siteState, menuOpen: false})
  }

  const timeOutDuration = 0

  useLayoutEffect(() => {
    setWindowWidth(window.innerWidth)
    window.addEventListener('resize', e => {
      setWindowWidth(window.innerWidth)
    })
    return window.removeEventListener('resize', e => {
      setWindowWidth(window.innerWidth)
    })
  }, [])

    useEffect(() => {
    if(!siteState.menuOpen){
      setLevelTwoHidden(true)
      window.setTimeout(() => {
        setUpdating(true)
        setLevelOne(null)
        setUpdating(false)
      }, 800)
    } 
  }, [siteState.menuOpen])

  useEffect(() => {
    if(levelTwoHidden || (levelTwoHidden && levelThreeHidden)) {
      setSiteState(prevState => ({...prevState, hideIcons: false}))
    }
    else {
      setSiteState(prevState => ({...prevState, hideIcons: true}))
    }
  }, [levelTwoHidden, levelThreeHidden])

  //Function to fade change level one
  const changeLevelOne = newLevelOne => {
    setLevelTwoHidden(true)
    setLevelThreeHidden(true)
    if(!levelOne) {
      setLevelOne(newLevelOne)
      setLevelTwoHidden(false)
    } else {
      window.setTimeout(() => {
        setLevelOne(newLevelOne)
        setLevelTwoHidden(false)
      }, timeOutDuration)
    }
  }

  //Function to fade change level two
  const changeLevelTwo = (newLevelTwo, type) => {
    setLevelThreeHidden(true)
    setLevelFourHidden(true)
    if (type === `productParentCategory` && !newLevelTwo?.categories) {
      if(windowWidth < 768 ) {
          setUpdatingLevelThree(true)
          setLevelThree(newLevelTwo)
          setUpdatingLevelThree(false)
          setLevelThreeHidden(false)
          setLevelFourHidden(false)
      } else {
        window.setTimeout(() => {
          setUpdatingLevelThree(true)
          setLevelTwo(null)
          setLevelThree(newLevelTwo)
          setUpdatingLevelThree(false)
          setLevelThreeHidden(false)
          setLevelFourHidden(false)
        }, timeOutDuration)
      }
    } 
    else {
      if(!levelTwo && !levelThree) {
          setLevelThree(newLevelTwo)
          setLevelTwo(newLevelTwo)
          setLevelThreeHidden(false)
          setLevelFourHidden(false)
      } else {
        window.setTimeout(() => {
          setUpdatingLevelThree(true)
          setLevelTwo(null)
          setLevelTwo(newLevelTwo)
          setLevelThree(newLevelTwo)
          setUpdatingLevelThree(false)
          setLevelThreeHidden(false)
          setLevelFourHidden(false)
        }, timeOutDuration)
      }
    }
  }

  //Function to fade change level three
  const changeLevelThree = newLevelThree => {
    setLevelFourHidden(true)
    if(!levelThree) {
      setLevelThree(newLevelThree)
      setLevelFourHidden(false)
    } else {
      window.setTimeout(() => {
        setLevelThree(newLevelThree)
        setLevelFourHidden(false)
        setLevelThreeHidden(false)
      }, timeOutDuration)
    }
  }

  const back = () => {
    if(!levelThreeHidden) {
      setLevelThreeHidden(true)
      setUpdatingLevelThree(true)
      setUpdating(true)
      window.setTimeout(() => {
        setLevelTwo(null)
        setLevelThree(null)
        setUpdatingLevelThree(false)
        setUpdating(false)
      }, timeOutDuration / 2)
    } else {
      setLevelTwoHidden(true)
      setUpdating(true)
      window.setTimeout(() => {
        setLevelOne(null)
        setUpdating(false)
      }, timeOutDuration / 2)
    }
  }

  const formatDate = (date) => {
    const d = DateTime.fromISO(date)
    return DateTime.fromISO(date).toFormat('dd.MM.yy')
  }

	const handleLevelThreeHeadingClick = () => {
		navigate(resolveLink(levelThree?._type, levelThree?.slug?.current))
		closeMenu()
	}

  return (
    <>
    {siteState.menuOpen && 
      <Global styles={css`
      body {
        overflow: hidden;
      }
      `}/>
    }
      <Section
        className={className}
        id="menu"
        css={css`
        `}
      >
        <LevelOneMenuList
          onUpdate={selectedItem => changeLevelOne(selectedItem)}
          links={links}
          spread={true}
          updating={updating}
          css={css`
            grid-column: 1/4;
            ${tablet}{
              grid-column: 1/13;
              opacity: ${levelTwoHidden ? `1` : `0`};
              transition: opacity 0.3s;
              pointer-events: ${levelTwoHidden ? `auto` : `none`};
              grid-row: 1;
            }
          `}
        />
        <div 
          css={css`
              grid-column: 4/12;
              opacity: ${levelTwoHidden ? `0` : `1`};
              pointer-events: ${levelTwoHidden ? `none` : `auto`};
              transition: opacity 0.3s;
              ${tablet}{
                grid-column: 1/13;
                grid-row: 1;
              }
          `}>
            <SlideDown 
              className={'my-dropdown-slidedown'} 
              css={css`
                transition: 0.3s;
                display: grid;
                align-content: start;
                grid-template-columns: repeat(8, 1fr);
                grid-column-gap: 20px;
                ${tablet}{
                
                grid-template-columns: repeat(12, 1fr);
              }
            `}>
              {levelOne === `About Us` &&
                <>
                  <div css={css`
                    display: none;
                    ${tablet}{
                      height: ${barShowing ? `138px` : `76px`};
                      align-items: center;
                      margin-left: 25px;
                      display: flex;
                      position: absolute;
                      top: 0px;
                      left: 0px;
                      z-index: 100;
                    }
                  `}>
                    <button
                      onClick={back}
                      className="h6"
                      css={css`
                        display: flex;
                        align-items: center;
                        opacity: ${levelTwoHidden ? `0` : `1`};
                        transition: opacity 0.3s;
                        pointer-events: ${levelTwoHidden ? `none` : `auto`};
                    `}>
                      <Larr css={css`
                        width: 8px;
                        height: 8px;
                        margin-right: 8px;
                      `}/>
                      back
                    </button>
                  </div>
                  <h4 css={css`
                    display: none;
                    ${tablet}{
                      display: block;
                      grid-column: 1/13;
                      margin-bottom: 52px;
                      grid-row: 1;
                    }
                  `}>
                    {levelOne}
                  </h4>
                  <div
                    css={css`
                      grid-column: 1/4;
                      margin-top: 10px;
                      ${tablet}{
                        grid-column: 1/10;
                      }
                  `}>
                    <p css={css`
                      padding-bottom: 35px;
                    `}>
                      {aboutText}
                    </p>
                    <Button to={'/about'}>{aboutLinkText}</Button>
                  </div>
                  <div css={css`
                      grid-column: 5/9;
                      margin-top: 10px;
                      ${tablet}{
                        grid-column: span 12;
                        grid-row: 2;
                      }
                  `}>
                    <Image
                      asset={aboutImage?.asset?.id}
                      aspectRatio={1.6}
                      css={css`
                        ${tablet}{
                          margin-bottom: 30px;
                        }
                      `}
                    />
                  </div>
                </>
              }
              {levelOne === `Showrooms` &&
                <>
                  <div css={css`
                      display: none;
                      ${tablet}{
                        height: ${barShowing ? `138px` : `76px`};
                        align-items: center;
                        margin-left: 25px;
                        display: flex;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                      }
                    `}>
                    <button
                      onClick={back}
                      className="h6"
                      css={css`
                        display: flex;
                        align-items: center;
                        opacity: ${levelTwoHidden ? `0` : `1`};
                        transition: opacity 0.3s;
                        pointer-events: ${levelTwoHidden ? `none` : `auto`};
                    `}>
                      <Larr css={css`
                        width: 8px;
                        height: 8px;
                        margin-right: 8px;
                      `}/>
                      back
                    </button>
                  </div>
                  <h4 css={css`
                    display: none;
                    ${tablet}{
                      display: block;
                      grid-column: 1/13;
                      margin-bottom: 52px;
                      grid-row: 1;
                    }
                  `}>
                    {levelOne}
                  </h4>
                  {showrooms?.map((showroom) =>(
                    <div
                      key={showroom._key}
                      css={css`
                        grid-column: span 8;
                        margin-bottom: 74px;
                        ${tablet}{
                          margin-bottom: 50px;
                          grid-column: span 12;
                        }
                    `}>
                      <h4 css={css`
                          margin-bottom: 28px;
                      `}>
                        {showroom.title}
                      </h4>
                      <p
                        className="h6"
                        css={css`
                          margin-bottom: 28px;
                        `}>
                          <div className="h6">{showroom?.address}</div>
                          <div className="h6">{showroom?.city} {showroom?.state} {showroom?.postCode}</div>
                          <div className="h6">{showroom?.country}</div>
                          <div className="h6">{showroom?.phoneNumber}</div>
                      </p>
                    </div>
                  ))}
                  <Button
                    to={`/showrooms`}
                    css={css`
                      grid-column: span 8;
                      margin-bottom: 2px;
                      ${tablet}{
                        grid-column: span 12;
                      }
                    `}
                  >More About Our Showrooms</Button>
                </>
              }
              {levelOne === `Brands` &&
                <>
                <div css={css`
                    display: none;
                    ${tablet}{
                      height: ${barShowing ? `138px` : `76px`};
                      align-items: center;
                      margin-left: 25px;
                      display: flex;
                      position: absolute;
                      top: 0px;
                      left: 0px;
                    }
                  `}>
                  <button
                    onClick={back}
                    className="h6"
                    css={css`
                      display: flex;
                      align-items: center;
                      opacity: ${levelTwoHidden ? `0` : `1`};
                      transition: opacity 0.3s;
                      pointer-events: ${levelTwoHidden ? `none` : `auto`};
                  `}>
                    <Larr css={css`
                      width: 8px;
                      height: 8px;
                      margin-right: 8px;
                    `}/>
                    back
                  </button>
                </div>
                <h4 css={css`
                  display: none;
                  ${tablet}{
                    display: block;
                    grid-column: 1/13;
                    margin-bottom: 52px;
                    grid-row: 1;
                  }
                `}>
                  {levelThreeHidden ? levelOne : levelTwo?.title}
                </h4>
                <div css={css`
                  grid-column: 1/4;
                  ${tablet}{
                    grid-column: 1/13;
                    grid-row: 2;
                    opacity: ${levelThreeHidden ? `1` : `0`};
                    transition: opacity 0.3s;
                    pointer-events: ${levelThreeHidden ? `auto` : `none`};
                  }
                `}>
                  <MenuList
                    onUpdate={selectedItem => changeLevelTwo(selectedItem)}
                    links={brands.slice(0, 11)}
                    brands={true}
                    updating={updating}
                    css={css`
                      margin-bottom: 62px;
                      ${tablet}{
                        margin-bottom: 22px;
                      }
                    `}
                  />
                  <Button to={`/brands`} css={css`margin-bottom: 2px;`}>More on Brands</Button>
                </div>
                <MenuCard
                  shortDesc={levelTwo?._rawContent?.shortText}
                  image={levelTwo?._rawContent?.image}
                  type={levelTwo?._type}
                  brandLink={resolveLink(levelTwo?._type, levelTwo?.title)}
                  link={resolveLink('brandFilter', levelTwo?.title)}
                  css={css`
                    grid-column: 6/9;
                    margin-top: 10px;
                    opacity: ${levelThreeHidden ? `0` : `1`};
                    pointer-events: ${levelThreeHidden ? `none` : `auto`};
                    transition: opacity 0.3s;
                    ${tablet}{
                      grid-column: 1/13;
                      margin-top: 0px;
                      grid-row: 2;
                    }
                `}/>
              </>
              }
              {levelOne === `Products` &&
                <>
                  <div css={css`
                    display: none;
                    ${tablet}{
                      height: ${barShowing ? `138px` : `76px`};
                      align-items: center;
                      margin-left: 25px;
                      display: flex;
                      position: absolute;
                      top: 0px;
                      left: 0px;
                    }
                  `}>
                    <button
                      onClick={back}
                      className="h6"
                      css={css`
                        display: flex;
                        align-items: center;
                        opacity: ${levelTwoHidden ? `0` : `1`};
                        transition: opacity 0.3s;
                        pointer-events: ${levelTwoHidden ? `none` : `auto`};
                    `}>
                      <Larr css={css`
                        width: 8px;
                        height: 8px;
                        margin-right: 8px;
                      `}/>
                      back
                    </button>
                  </div>
                  <div css={css`
                    grid-column: 1/3;
                    grid-row: 1;
                    ${tablet}{
                      opacity: ${!levelThreeHidden ? `0` : `1`};
                      pointer-events: ${!levelThreeHidden ? `none` : `auto`};
                      grid-column: 1/13;
                    }
                  `}>
                    <h4 css={css`
                      display: none;
                      ${tablet}{
                        display: block;
                        margin-bottom: 52px;
                      }
                    `}>
                      {levelOne}
                    </h4>
                    <MenuList
                      onUpdate={(selectedItem, type) => changeLevelTwo(selectedItem, type)}
                      links={storeCategories}
                      updating={updating}
                      clickableOntablet={true}
                      css={css`
                        margin-bottom: 62px;
                        ${tablet}{
                          margin-bottom: 22px;
                        }
                      `}
                    />
                    <Button to={`/products`} css={css`margin-bottom: 2px;`}>Shop all</Button>
                  </div>
                  <div css={css`
                    grid-column: 3/9;
                    display: grid;
                    grid-template-columns: repeat(6, 1fr);
                    grid-column-gap: 20px;
                    opacity: ${!levelThreeHidden ? `1` : `0`};
                    pointer-events: ${!levelThreeHidden ? `auto` : `none`};
                    transition: opacity 0.3s;
                    ${tablet}{
                      grid-column: 1/13;
                      grid-row: 1;
                    }
                  `}>
                    <>
                      <h4 
											onClick={() => levelThree ? handleLevelThreeHeadingClick() : null}	
											css={css`
                        display: none;
                        ${tablet}{
                          display: block;
                          margin-bottom: 52px;
                          grid-column: 1/6;
                          grid-row: 1;
                        }
                      `}>
                        {levelTwo ? levelTwo?.title : levelThree?.category?.title ? levelThree?.category?.title : levelThree?.title}
                      </h4>
                      <MenuList
                        onUpdate={selectedItem => changeLevelThree(selectedItem)}
                        updating={updatingLevelThree}
                        links={levelTwo?.categories}
                        parent={levelTwo}
                        css={css`
                          grid-column: 1/3;
                          ${tablet}{
                            grid-column: 6/13;
                            grid-row: 1;
                          }
                        `}
                      />
                      { levelThree &&
                        <MenuCard
                        shortDesc={levelThree?.textSnippet}
                        image={levelThree?.image?.asset?.id}
                        link={resolveLink(levelThree?._type, levelThree?.slug?.current, levelThree?.defaultParentCategory?.slug?.current)}
                        type={levelThree?._type}
                        css={css`
                          grid-column: 4/7; 
                          margin-top: 10px;
                          opacity: ${levelFourHidden ? `0` : `1`};
                          transition: opacity 0.3s;
                          ${tablet}{
                            grid-column: 1/13;
                            grid-row: 2;
                          }
                        `}>
                        </MenuCard>
                      }
                    </>
                  </div>
                </>
              }
              {levelOne === `Journal` &&
                <>
                  <div css={css`
                    display: none;
                    ${tablet}{
                      height: ${barShowing ? `138px` : `76px`};
                      align-items: center;
                      margin-left: 25px;
                      display: flex;
                      position: absolute;
                      top: 0px;
                      left: 0px;
                    }
                  `}>
                    <button
                      onClick={back}
                      className="h6"
                      css={css`
                        display: flex;
                        align-items: center;
                        opacity: ${levelTwoHidden ? `0` : `1`};
                        transition: opacity 0.3s;
                        pointer-events: ${levelTwoHidden ? `none` : `auto`};
                    `}>
                      <Larr css={css`
                        width: 8px;
                        height: 8px;
                        margin-right: 8px;
                      `}/>
                      back
                    </button>
                  </div>
                  <h4 css={css`
                    display: none;
                    ${tablet}{
                      display: block;
                      grid-column: ${levelThreeHidden ? `1/13` : `1/7`};
                      margin-bottom: 52px;
                      grid-row: 1;
                    }
                  `}>
                    {levelThreeHidden ? levelOne : levelTwo.title}
                  </h4>
                  <div css={css`
                      grid-column: 1/4;
                      margin-top: 10px;
                      ${tablet}{
                        grid-column: 1/10;
                      }
                  `}>
                    <p css={css`
                      padding-bottom: 44px;
                      ${tablet}{
                        padding-bottom: 34px;
                      }
                    `}>{journalText}</p>
                    <Button
                      css={css`
                        ${tablet}{
                          display: none;
                        }
                    `}
                      to={`/journal`}
                    >{journalLinkText}</Button>
                  </div>
                  <div css={css`
                    grid-column: 5/10;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    column-gap: 20px;
                    ${tablet}{
                      column-gap: 10px;
                      grid-column: 1/13;
                      margin-bottom: 40px;
                    }
                  `}>
                    {featuredArticles?.map(article => (
                      <ArticleCard
                        image={article?._rawContent?.thumb?.asset}
                        link={resolveLink(article?._type, article?.slug?.current)}
                        categories={article?._rawContent?.categories}
                        date={formatDate(article?.datePublished)}
                        title={article?.title}
                      />
                    ))}
                  </div>
                  <Button
                    to={`/journal`}
                    css={css`display: none; ${tablet}{display: block;}`}
                  >See all Articles</Button>
                </>
              }
            </SlideDown>
        </div>
      </Section>
    </>
  )
}

Menu.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string,
  })),
  brands: PropTypes.shape({
    links: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      image: PropTypes.object,
      shortDesc: PropTypes.string,
      link: PropTypes.string,
    })),
  })
}

Menu.defaultProps = {
  links: [
    {
      key: `item1`,
      title: `About Us`,
      id: `aboutUs`,
    },
    {
      key: `item2`,
      title: `Our Showrooms`,
      id: `ourShowrooms`,
    },
    {
      key: `item3`,
      title: `Our Brands`,
      id: `ourBrands`,
    },
    {
      key: `item4`,
      title: `Products`,
      id: `store`,
    },
    {
      key: `item5`,
      title: `Journal`,
      id: `Journal`,
    },
    {
      key: `item6`,
      title: `Contact`,
      id: `Contact`,
      link: `/`,
    },
    {
      key: `item7`,
      title: `Trade`,
      id: `Trade`,
      link: `/account`,
    },
  ],
  aboutUsData: {
    text: `Driven by a desire for elegant, versatile and contemporary furniture that is made for living.`,
    link: `/`,
  },
  showroomsData: {
    showrooms: [
      {
        title: `Sydney Showroom`,
        address: {
          address: `840 Bourke Street`,
          city: `Waterloo`,
          stateProvince: `NSW`,
          postCode: 2017,
          country: `Australia`,
        },
        phoneNumber: `+61 2 8399 5630`,
      },
      {
        title: `Melbourne Showroom`,
        address: {
          address: `610 Church Street`,
          city: `Cremorne`,
          stateProvince: `VIC`,
          postCode: 3121,
          country: `Australia`,
        },
        phoneNumber: `+61 3 9429 1221`,
      },
    ],
    allShowroomsLink: `/`,
  },
  brandsProp: {
    links: [
      {
        title: `Capdell`,
        key: `1`,
        children: {
          description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam corporis voluptas ut.`,
          link: `/`,
        }
      },
      {
        title: `Cattelan Italia`,
        key: `2`,
        children: {
          description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam corporis voluptas ut.`,
          link: `/`,
        }
      },
      {
        title: `CVL`,
        key: `3`,
        children: {
          description: `CVL Luminaires, located in the Loire Valley, brings over fifty years of experience in the design and production of high quality lighting solutions...`,
          link: `/`,
        }
      },
      {
        title: `Driade`,
        key: `4`,
        children: {
          description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam corporis voluptas ut.`,
          link: `/`,
        }
      },
      {
        title: `eForma`,
        key: `5`,
        children: {
          description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam corporis voluptas ut.`,
          link: `/`,
        }
      },
      {
        title: `Erba`,
        key: `6`,
        children: {
          description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam corporis voluptas ut.`,
          link: `/`,
        }
      },
      {
        title: `Leolux`,
        key: `7`,
        children: {
          description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam corporis voluptas ut.`,
          link: `/`,
        }
      },
      {
        title: `Luceplan`,
        key: `8`,
        children: {
          description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam corporis voluptas ut.`,
          link: `/`,
        }
      },
      {
        title: `MisuraEmme`,
        key: `9`,
        children: {
          description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam corporis voluptas ut.`,
          link: `/`,
        }
      },
      {
        title: `Montis`,
        key: `10`,
        children: {
          description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam corporis voluptas ut.`,
          link: `/`,
        }
      },
    ],
    allBrandsLink: `/`,
  },
  storeData: {
    links: [
      {
        title: `New Arrivals`,
        key: `1`,
        subLinks: [
          {
            title: `New Arrival Sub Item`,
            key: `1`,
            children: {
              image: ``,
              description: `A place for transormative rest and rejuvination,  our range of elevated bedroom furniture will completely transform your sleeping quarters.`,
              link: `/`,
            }
          }
        ]
      },
      {
        title: `Ready to Go`,
        key: `2`,
        subLinks: [
        ]
      },
      {
        title: `Living`,
        key: `3`,
        subLinks: [
        ]
      },
      {
        title: `Bedroom`,
        key: `4`,
        subLinks: [
          {
            title: `Beds`,
            key: `1`,
            children: {
              image: ``,
              description: `A place for transormative rest and rejuvination,  our range of elevated bedroom furniture will completely transform your sleeping quarters.`,
              link: `/`,
            }
          },
          {
            title: `Bedside Tables`,
            key: `2`,
            children: {
              image: ``,
              description: `A place for transormative rest and rejuvination,  our range of elevated bedroom furniture will completely transform your sleeping quarters.`,
              link: `/`,
            }
          },
          {
            title: `Dressers`,
            key: `3`,
            children: {
              image: ``,
              description: `A place for transormative rest and rejuvination,  our range of elevated bedroom furniture will completely transform your sleeping quarters.`,
              link: `/`,
            }
          },
          {
            title: `Tallboys`,
            key: `4`,
            children: {
              image: ``,
              description: `A place for transormative rest and rejuvination,  our range of elevated bedroom furniture will completely transform your sleeping quarters.`,
              link: `/`,
            }
          }
        ]
      },
      {
        title: `Dining`,
        key: `5`,
        subLinks: [
        ]
      },
      {
        title: `Complements`,
        key: `6`,
        subLinks: [
        ]
      },
      {
        title: `Lighting`,
        key: `7`,
        subLinks: [
        ]
      },
      {
        title: `Storage`,
        key: `7`,
        subLinks: [
        ]
      },
      {
        title: `Wardrobe`,
        key: `8`,
        subLinks: [
        ]
      },
      {
        title: `Wardrobe`,
        key: `8`,
        subLinks: [
        ]
      },
    ]
  },
  journalData: {
    description: `A collection of the latest industry news, Misura updates, brand profiles & more — Don’t forget to sign up to stay up to date.`,
    allArticlesLink: `/`,
    featuredArticles: [
      {
        type: `news`,
        date: `17.08.20`,
        title: `Victoria Point Foyer by Atoma Studio`,
        link: `/`,
      },
      {
        type: `brand profile`,
        date: `17.08.20`,
        title: `The Legacy of Lago`,
        link: `/`,
      }
    ]
  }
}
export default Menu
